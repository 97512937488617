@import "bootstrap";
@import "simssa";

.jumbotron {
  background: url('http://localhost:4000/assets/jumbobg.jpg') center;
}

.headerimage:before {
  background: url('http://localhost:4000/assets/Cantus_A.jpg') center;
}

.headerimage_people:before {
  background: url('http://localhost:4000/assets/nuns.jpg') center;
}
